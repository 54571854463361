@import "index", "font", "basic", "layout";

body > header, body > article, body > footer {
  padding: 1.5em; }

@media (min-width: 32em) {
  body > header, body > article, body > footer {
    padding: 1.75em calc(38% - 12em); } }



body > header {
  background: rgba($light, 0.02);
  box-shadow: 0 0 .6em rgba($dark, 0.05);
  border-bottom: 1px solid reduce(16); }




