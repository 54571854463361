body > header, body > footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

article header {
  margin-bottom: 1.5em; }

article header h1 {
  font-size: 1.8em;
  margin: 0 0 .1em; }

nav {
  margin: .5em -.8em; }

nav a {
  margin: .5em .8em; }
